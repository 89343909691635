import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CrudlsStart from "components/pages/CrudlsStart";
import LoginPage from "components/pages/LoginPage";
import LogoutPage from "components/pages/LogoutPage";
import MainView from "./MainView";
import LoginView from "./LoginView";
import UserGuide from "components/pages/UserGuide";
import { ConfirmDialogProvider } from "common/modal/ConfirmDialog";
import Crudls from "components/pages/Crudls";
import HtmlTextList from "components/pages/html_text/HtmlTextList";
import HtmlTextNew from "components/pages/html_text/HtmlTextNew";
import HtmlText from "components/pages/html_text/HtmlText";

export default function CrudlsMainContent() {
  return (
    <ConfirmDialogProvider>
      <Router>
        <Routes>
          <Route element={<MainView />}>
            <Route path="/" element={<CrudlsStart />} />
            <Route path="/html-text">
              <Route path="list" element={<HtmlTextList />} />
              <Route path="new" element={<HtmlTextNew />} />
              <Route path=":htmlTextId" element={<HtmlText />} />
            </Route>
            <Route path="/crudls" element={<Crudls />} />
            <Route path="/uputstvo" element={<UserGuide />} />
          </Route>
          <Route element={<LoginView />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
          </Route>
        </Routes>
      </Router>
    </ConfirmDialogProvider>
  );
}
