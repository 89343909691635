import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { HtmlTextResult } from "common/html_text/dto/HtmlTextResult";
import { NavLink } from "react-router-dom";
import { useHtmlTextDelete } from "hooks/crudls/html_text/useHtmlTextDelete";
import { QUERY_KEYS } from "constant/react_query";
import { htmlTextLinkSingle } from "common/html_text/html_text_link_common";
import useConfirm from "common/modal/ConfirmDialog";

type HtmlTextSingleNavProps = {
  htmlText: HtmlTextResult;
  hideEditNav?: boolean;
  hideReadNav?: boolean;
};

const HtmlTextSingleNav = ({
  htmlText,
  hideEditNav,
  hideReadNav,
}: HtmlTextSingleNavProps) => {
  const { htmlTextDeleteMutate } = useHtmlTextDelete({
    invalidateKeys: [QUERY_KEYS.CRUDLS_HTML_TEXT_SEARCH],
  });

  const actionConfirm = useConfirm();

  const deleteHandle = async () => {
    const check = await actionConfirm({
      title: "Html text",
      message: "Are you sure you want to delete html text",
    });
    if (check) {
      htmlTextDeleteMutate(htmlText.id);
    }
  };

  return (
    <DivNav>
      <DeleteIcon onClick={deleteHandle} />
      {!hideEditNav && (
        <NavLink to={htmlTextLinkSingle(htmlText.id, true)}>
          <EditIcon />
        </NavLink>
      )}
      {!hideReadNav && (
        <NavLink to={htmlTextLinkSingle(htmlText.id)}>
          <ReadMoreIcon />
        </NavLink>
      )}
    </DivNav>
  );
};

const DivNav = styled.div`
  margin-top: 0.75rem;
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
`;

export default HtmlTextSingleNav;
