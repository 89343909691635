import { useMutation } from "@tanstack/react-query";
import { htmlTextDelete } from "common/api/crudls/crudls_html_text";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseHtmlTextDeleteParams = {
  invalidateKeys?: string[];
};

export const useHtmlTextDelete = (hookParams?: UseHtmlTextDeleteParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: htmlTextDeleteMutate } = useMutation({
    mutationFn: async (id: string) => await htmlTextDelete(id),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { htmlTextDeleteMutate };
};
