import { useEffect, useState } from "react";
import styled from "styled-components";
import { useErrorBoundary } from "react-error-boundary";
import { axiosCrudls } from "common/api/axios/crudls_axios";

const CrudlsStart = () => {
  const { showBoundary } = useErrorBoundary();
  const [msgAuthRequiredCrudls, setMsgAuthRequiredCrudls] = useState<any>(
    "crudls not accessed"
  );

  useEffect(() => {
    axiosCrudls
      .get("auth/authrequired")
      .then((res) => {
        console.log("crudls res authrequired", res);
        setMsgAuthRequiredCrudls(res.statusText);
      })
      .catch((err) => {
        console.log("crudls res authrequired err", err.response.status);
      });
  }, []);

  return (
    <div>
      <div>START</div>
      <div>Crudls: {msgAuthRequiredCrudls}</div>
    </div>
  );
};

export default CrudlsStart;
