import React, { useEffect } from "react";
import styled from "styled-components";
import HtmlTextSingleNav from "components/common/html_text/HtmlTextSingleNav/HtmlTextSingleNav";
import { HtmlTextResult } from "common/html_text/dto/HtmlTextResult";

type HtmlTextReadProps = {
  htmlText: HtmlTextResult;
};

const HtmlTextRead = ({ htmlText }: HtmlTextReadProps) => {
  return (
    <div>
      <div>{htmlText.slug}</div>
      <div>{htmlText.content}</div>
      <DivNav>
        <HtmlTextSingleNav htmlText={htmlText} hideReadNav={true} />
      </DivNav>
    </div>
  );
};

const DivNav = styled.div`
  margin-top: 1.5rem;
`;

export default HtmlTextRead;
