import { NAV_ELEMENTS } from "constant/navigation";
import { MainNavElement } from "type/navigation/main_navigation.type";

export const GENERAL_NAV_ELEMENTS: MainNavElement[] = [NAV_ELEMENTS.UPUTSTVO];
export const NEW_ITEM_NAV_ELEMENTS: MainNavElement[] = [
  NAV_ELEMENTS.HTML_TEXT_NEW,
];
export const LIST_MAIN_NAV_ELEMENTS: MainNavElement[] = [
  NAV_ELEMENTS.HTML_TEXT_LIST,
];
