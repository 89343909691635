import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import MainNavigation from "components/navigation/MainNavigation";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthCurrent } from "hooks/auth/auth_current";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";

export default function MainView() {
  const authCurrent = useAuthCurrent();
  const [showContent, setShowContent] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (authCurrent) {
      if (authCurrent.uId !== "") {
        setShowContent(true);
      } else {
        setShowContent(false);
        navigate("/login");
      }
    } else {
      setShowContent(false);
    }
  }, [authCurrent]);

  return (
    (showContent && (
      <div className="crudls-main">
        <MainNavigation />
        <Toaster />
        <Div>
          <Outlet />
        </Div>
      </div>
    )) ||
    null
  );
}
const Div = styled.div`
  display: flex;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: calc(100% - 4rem);
  flex-direction: column;
  color: #764141;
  overflow: auto;

  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    margin-left: calc(240px + 2%);
    margin-top: 4rem;
    width: calc(100% - 240px - 4%);
  }
`;

// SCREEN_WIDTH_BREAKPOINT
