import { HtmlTextResult } from "common/html_text/dto/HtmlTextResult";
import SearchResultList, {
  PageNavigation,
} from "components/common/SearchResultList";
import HtmlTextItem from "./HtmlTextItem";
import { useEffect, useState } from "react";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { HtmlTextSearchParams } from "common/api/crudls/crudls_html_text";
import { HTML_TEXT_PER_PAGE_MAX } from "constant/html_text";
import { useHtmlTextSearch } from "hooks/crudls/html_text/useHtmlTextSearch";
import { htmlTextSearchFieldsGet } from "common/html_text/html_text_search";
import HtmlTextSearchFilters from "components/common/html_text/HtmlTextSearchFilters";

const HtmlTextList = () => {
  const [searchParams, setSearchParams] = useState<HtmlTextSearchParams>({
    limit: HTML_TEXT_PER_PAGE_MAX,
    skip: 0,
  });
  const htmlTextListResult = useHtmlTextSearch(searchParams);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([
      QUERY_KEYS.CRUDLS_HTML_TEXT_SEARCH,
    ]);
  }, [searchParams]);

  let results: HtmlTextResult[] = htmlTextListResult
    ? htmlTextListResult["items"]
    : [];
  const resTotal: number = htmlTextListResult
    ? htmlTextListResult["resTotal"]
    : 0;
  const pageNavigationHandle = (pageNum: number) => {
    const skip = pageNum * HTML_TEXT_PER_PAGE_MAX;
    setSearchParams({ ...searchParams, skip });
  };
  const pageNavigation: PageNavigation = {
    resTotal,
    limit: HTML_TEXT_PER_PAGE_MAX,
    skip: searchParams.skip ?? 0,
    pageNavigationHandle,
  };
  const searchHandle = (formData: any) => {
    const searchVals = htmlTextSearchFieldsGet(formData);

    setSearchParams({
      ...searchParams,
      ...searchVals,
      skip: 0,
    });
  };

  return (
    <div>
      <HtmlTextSearchFilters searchHandle={searchHandle} />
      <SearchResultList<HtmlTextResult>
        renderSingle={(htmlText: HtmlTextResult) => (
          <HtmlTextItem htmlText={htmlText} key={`htmlText_${htmlText.id}`} />
        )}
        results={results}
        pageNavigation={pageNavigation}
      />
    </div>
  );
};

export default HtmlTextList;
