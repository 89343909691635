import { AxiosInstance } from "axios";
import { apiTypeUrlBaseGet } from "../api_common";
import { API_APP_NAMES, API_TYPES } from "../api_constants";
import { authorizationObDefault, axiosInstanceCreate } from "./axios_common";
import { axiosInstance401Handle } from "./axios_intercepter_common";

const crudlsUrlBase: string = apiTypeUrlBaseGet(API_TYPES.CRUDLS);

const crudlsAuth = authorizationObDefault();

const API_CRUDLS_RETRY_LIMIT: number = 3;

export const axiosCrudls: AxiosInstance = axiosInstanceCreate(crudlsUrlBase);

axiosCrudls.interceptors.response.use(null, async (err) => {
  if (typeof err.config.retryLimit === "undefined") {
    err.config.retryLimit = API_CRUDLS_RETRY_LIMIT;
  }
  if (err.config.retryLimit === 0) {
    throw err;
  }
  err.config.retryLimit--;
  if (err.response.status === 401) {
    try {
      await axiosInstance401Handle(
        axiosCrudls,
        API_APP_NAMES.CRUDLS,
        crudlsAuth
      );
    } catch (err) {
      throw err;
    }
  }
  const response = await axiosCrudls(err.config);
  return response;
});
